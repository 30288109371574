/********** FONTS ***********/
@font-face {
  font-family: "Fondamento";
  src: url('./fonts/fondamento-regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cardo";
  src: url('./fonts/Cardo-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cardo";
  src: url('./fonts/Cardo-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cardo";
  src: url('./fonts/Cardo-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

/********** ELEMENTS *********/
body {
  background-color: #f9f9f7;
  color: #282c34;
  margin: 0;
  font-family: "Cardo",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 { font-family: "Fondamento"; font-size: 2.75rem; }
h4 { font-family: "Cardo"; 
  font-size: 1.50rem; font-style: italic; font-weight: normal;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
a { text-decoration: none; color: #61dafb;}
a:visited { color: #61dafb;; }

/********** GENERAL LAYOUT *********/
.centered-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/********** PAGE - LANDING *********/
.Landing { 
  flex: 0 0 22em;
  .content { max-width: 20rem; }  
  h1 { text-align: center; }
  h4 { text-align: center; }
  p { text-align: justify; }
}

